// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-app-terms-jsx": () => import("./../../../src/pages/app-terms.jsx" /* webpackChunkName: "component---src-pages-app-terms-jsx" */),
  "component---src-pages-giveaway-jsx": () => import("./../../../src/pages/giveaway.jsx" /* webpackChunkName: "component---src-pages-giveaway-jsx" */),
  "component---src-pages-legal-jsx": () => import("./../../../src/pages/legal.jsx" /* webpackChunkName: "component---src-pages-legal-jsx" */),
  "component---src-pages-legals-eula-jsx": () => import("./../../../src/pages/legals-eula.jsx" /* webpackChunkName: "component---src-pages-legals-eula-jsx" */),
  "component---src-pages-legals-limited-warranty-jsx": () => import("./../../../src/pages/legals-limited-warranty.jsx" /* webpackChunkName: "component---src-pages-legals-limited-warranty-jsx" */),
  "component---src-pages-returns-policy-jsx": () => import("./../../../src/pages/returns-policy.jsx" /* webpackChunkName: "component---src-pages-returns-policy-jsx" */),
  "component---src-pages-terms-jsx": () => import("./../../../src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */),
  "component---src-pages-thank-you-jsx": () => import("./../../../src/pages/thank-you.jsx" /* webpackChunkName: "component---src-pages-thank-you-jsx" */),
  "component---src-templates-blog-category-jsx": () => import("./../../../src/templates/blogCategory.jsx" /* webpackChunkName: "component---src-templates-blog-category-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-blogpost-jsx": () => import("./../../../src/templates/blogpost.jsx" /* webpackChunkName: "component---src-templates-blogpost-jsx" */),
  "component---src-templates-faq-jsx": () => import("./../../../src/templates/faq.jsx" /* webpackChunkName: "component---src-templates-faq-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */)
}

